import React, { useState ,useEffect} from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../draftEditor";
import Filter from "../filter";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../utilities/imageTestApi/image";
import { Pincode } from "../../entities/action/action";
import {
    addSightSeeing
  } from "../../entities/action/action";

const AddSightseeing = (props) => {
  const dispatch = useDispatch();
  const [message, updateMessage] = useState("");
  const [tAndC, updateTAndC] = useState("");
  const [openDays, updateOpenDays] = useState([]);
  const [sightseeingImage, updatesightSeeingImage] = useState({});

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pin, setpin] = useState("");


  const cityState = useSelector((state) => state.account.cityState);
  const [pinError, setPinError] = useState('');
  const handleChange1 = (event) => {
    const { name, value } = event.target;

    // Validate pincode format using a regular expression
    if (name === 'pincode') {
      if (/^[0-9]{6}$/.test(value)) {
        setpin(value);
        setPinError(''); // Clear any previous error
      } else {
        setPinError('Pincode must be a 6-digit number');
      }
    }
  };
  const myfunc = (e) => {
    if (e.target?.value.length === 6) {
      dispatch(Pincode(e.target?.value));
    } else {
      setState("");
      setCity("");
    }
  };

  useEffect(() => {
    if (cityState === null) {
        setState('');
        setCity('');
    } else {
        setState(cityState?.state);
        setCity(cityState?.city);
    }
  }, [cityState]);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const img = await Image(sightseeingImage);
          const newData = {
            bestTimeToVisit: values.bestTimeToVisit,
            city: city,
            // closeTime: values.closeTime,
            description: message,
            pincode: pin,
            openDays: openDays.toString(),
            // openTime: values.openAt,
            sightSeeingId: 0,
            sightSeeingName: values.sightseeingName,
            sightSeeingType: values.sightSeeingType,
            state: state,
            termsAndCondition: tAndC,
            ticketCostForeigners: values.foreignersCost,
            ticketCostIndians: values.indianCost,
            ticketCostForeignersChild: values.foreignersChildCost,
            ticketCostIndiansChild: values.indianChildCost,
            ticketRequired: values.ticketRequired === "Yes" ? true : false,
            timeUnit: values.timeUnit,
            travelType: values.travelType,
            visitDuration: values.visitDuration,
            worldLocation: values.worldLocation,
            sightseeingImage: img,
            winterCloseTime: values.closeTime,
            winterOpenTime: values.openAt,
            summerCloseTime: values.scloseTime,
            summerOpenTime: values.sopenAt,
          };
          const response = await addSightSeeing(newData);
          if (response.successful) {
            alert("Successfully added Sightseeing");
            props.click();
          }
        console.log(newData);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                Sightseeing Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Sightseeing Type"
                  name="sightSeeingType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sightseeingType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPromo" as={Row}>
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="sightseeingImage"
                  onChange={(e) => updatesightSeeingImage(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCityState" as={Row}>
              <Form.Label column sm="3">
                World Location:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  name="worldLocation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.worldLocation}
                  required
                >
                  <option value="">--select--</option>
                  <option value="India">India</option>
                  <option value="Foreign">Foreign</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Zip Code:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Zip Code"
                  name="pincode"
                  onChange={handleChange1}
                  onBlur={myfunc}
                  value={values.pincode}
                  isInvalid={!!pinError}
                  required
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                State:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state}
                  readOnly
                  required
                >
                  {/* <option value=""></option> */}
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                City:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={city}
                  readOnly
                  required
                >
                  {/* <option value=""></option> */}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCName" as={Row}>
              <Form.Label column sm="3">
                Sightseeing Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Sightseeing Name"
                  name="sightseeingName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sightseeingName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTicketRequired" as={Row}>
              <Form.Label column sm="3">
                Ticket Required:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  name="ticketRequired"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticketRequired}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBestTimeToVisit" as={Row}>
              <Form.Label column sm="3">
                Best time to visit:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Best time to visit"
                  name="bestTimeToVisit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bestTimeToVisit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTravel" as={Row}>
              <Form.Label column sm="3">
                Travel Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Travel Type"
                  name="travelType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.travelType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVisitDuration" as={Row}>
              <Form.Label column sm="3">
                Visit Duration:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Visit Duration"
                  name="visitDuration"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.visitDuration}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Time Unit:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Time Unit"
                  name="timeUnit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.timeUnit}
                  required
                />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Description:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateTAndC(text)}
                  text={tAndC}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>Open Days:</Col>
              <Col xs={9}>
                <Filter handleFilter={(filter) => updateOpenDays(filter)} />
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
                Timing:
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    SUMMER OPEN:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="sopenAt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sopenAt}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    SUMMER CLOSE:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="scloseTime"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.scloseTime}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
                Timing:
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    WINTER OPEN:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="openAt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.openAt}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    WINTER CLOSE:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="closeTime"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.closeTime}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
                Ticket Cost:
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    Indian Adult:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      step="any"
                      min={0}
                      placeholder="Indian"
                      name="indianCost"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.indianCost}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    Foreigners Adult:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      step="any"
                      min={0}
                      placeholder="Foreigners"
                      name="foreignersCost"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.foreignersCost}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    Indian Child:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      step="any"
                      min={0}
                      placeholder="Indian"
                      name="indianChildCost"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.indianChildCost}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    Foreigners Child:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      step="any"
                      min={0}
                      placeholder="Foreigners"
                      name="foreignersChildCost"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.foreignersChildCost}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Sightseeing
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddSightseeing;
