import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import Page from '../pagination/pagination';

const TravelDepositList = (props)=>{
    const [dataSet,updateDataSet] = useState([0]);
    const [count,updateCount] = useState(1)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
    const handleDelete=async(data)=>{
            const newdata= {
                "emailID": "string",
                "id": data.id,
                "sessionKey": "string",
                "theme": data.theme
              }
            const res = await fetch('https://api.zupptravel.com/deleteTravelDeposit',{
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                });
            const json = await res.json();
            if(json.successful){
                alert('Travel Deposit deleted!');
            }else{
                alert('Error')
            }
    }
    useEffect(()=>{
        (async()=>{
            const newdata = {
                "emailId": "string",
                "password": "string",
                "phoneno": "string",
                "sessionKey": "string"
              }
            const res = await fetch('https://api.zupptravel.com/getTravelDeposit',{
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body:JSON.stringify(newdata)
            })
            const json = await res.json();
            if(json.successful){
                updateDataSet(json.travelDeposits);
            }else{
                alert("Error! Please try again later");
            }
        })()
    },[])
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Theme</th>
                        <th>Deposit Frequency</th>
                        <th>Date</th>
                        <th>ROI 1</th>
                        <th>ROI 2</th>
                        <th>ROI 3</th>
                        <th>ROI 4</th>
                        <th>ROI 5</th>
                        <th>ROI 6</th>
                        <th>ROI 7</th>
                        <th>ROI 8</th>
                        <th>ROI 9</th>
                        <th>ROI 10</th>
                        <th>ROI 11</th>
                        <th>ROI 12</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.theme}</td>
                                <td>{data.depositFrequency}</td>
                                <td>{data.tableEntryDate!=undefined?data.tableEntryDate.split('T')[0]:null}</td>
                                <td>{data.month1ROI}</td>
                                <td>{data.month2ROI}</td>
                                <td>{data.month3ROI}</td>
                                <td>{data.month4ROI}</td>
                                <td>{data.month5ROI}</td>
                                <td>{data.month6ROI}</td>
                                <td>{data.month7ROI}</td>
                                <td>{data.month8ROI}</td>
                                <td>{data.month9ROI}</td>
                                <td>{data.month10ROI}</td>
                                <td>{data.month11ROI}</td>
                                <td>{data.month12ROI}</td>
                                <td className={classes.CenterRow}>
                                    <Button onClick={()=>handleDelete(data)} style={{marginRight:'5px'}} variant="danger">Delete</Button>
                                    
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default TravelDepositList;