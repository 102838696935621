import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import { Addairport } from '../../entities/action/action';

const AddAirport = (props)=>{
 
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{airportCityApplicable:"Yes"}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    // console.log(values);
                    setSubmitting(true);
                        const newData = { 
                            "airportCode": values.airportIATACode,
                            "airportCountryCode": values.airportCountryCode,
                            "airportCountryName": values.airportCountryName,
                            "airportId": 0,
                            "airportName": values.airportName,
                            "airportCityCode": values.airportCityCode,
                            "airportCityName": values.airportCityName,
                            "airportNumAirports": values.numberOfAirports,
                            "airportTimezone": values.airportTimezone ,
                            "airportCity": values.airportCity,
                            "airportLat": values.airportLat,
                            "airportLon": values.airportLon,
                        }
                        console.log(newData)
                        const res = await Addairport(newData);
                        console.log(res)
                        if(res.success){
                            alert("Successfully Added Airport");
                            resetForm();
                        }else{
                            alert('Not added! Please try again.')
                            resetForm();
                        }
                    setSubmitting(false);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasic" as={Row}>
                                            <Form.Label column sm="3">Airport Name:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Airport Name" 
                                                name="airportName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportName}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicIATACode" as={Row}>
                                            <Form.Label column sm="3">Airport IATA Code:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Airport IATA Code" 
                                                name="airportIATACode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportIATACode}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCName" as={Row}>
                                            <Form.Label column sm="3">Airport Country Name:</Form.Label>
                                            <Col sm="9"> 
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Airport Country Name" 
                                                name="airportCountryName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportCountryName}
                                                required/>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicCountryCode" as={Row}>
                                            <Form.Label column sm="3">Airport Country Code:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Airport Country Code" 
                                                name="airportCountryCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportCountryCode}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCityCode" as={Row}>
                                            <Form.Label column sm="3">Airport City Code:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Airport City Code" 
                                                name="airportCityCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportCityCode}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCityName" as={Row}>
                                            <Form.Label column sm="3">Airport City:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Airport City" 
                                                name="airportCity"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportCity}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCityName" as={Row}>
                                            <Form.Label column sm="3">Airport City Name:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Airport City Name" 
                                                name="airportCityName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportCityName}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicnumberOfAirports" as={Row}>
                                            <Form.Label column sm="3">Number of Airports:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="number"
                                                min={0} 
                                                placeholder="Number Of Airports" 
                                                name="numberOfAirports"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.numberOfAirports}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasic" as={Row}>
                                            <Form.Label column sm="3">Airport Timezone:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Airport Timezone" 
                                                name="airportTimezone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportTimezone}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCityName" as={Row}>
                                            <Form.Label column sm="3">Airport Lon:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Airport Lon." 
                                                name="airportLon"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportLon}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCityName" as={Row}>
                                            <Form.Label column sm="3">Airport Lat:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Airport Lat." 
                                                name="airportLat"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.airportLat}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Save Airport</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default AddAirport;