import React,{useEffect, useState} from 'react';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import {Button,Container} from 'react-bootstrap';
import DraftEditor from '../draftEditor';
import { Markup } from 'interweave';

const Pages = (props)=>{
    const [message,updateMessage] = useState('');
    const [downloaded,updateDownloaded] = useState('');
    // console.log(message)
    useEffect(()=>{
        (async()=>{
            const newdata = {
                "pageManagementId": props.id
            }
            const myresponse = await fetch("https://api.zupptravel.com/pageManagement/getPageManagement", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newdata),
            });
            const myjson = await myresponse.json();
            if(myjson.entity!=null){
                updateDownloaded(myjson.entity.text);
            }
        })()
    },[props.type])
    const handleSubmit = async(id,type)=>{
        const newData = {
            "pageManagementId": id,
            "serviceName": type,
            "text": message,
            "userId": "string"
          }
        const myresponse = await fetch("https://api.zupptravel.com/pageManagement/updatePageManagement", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
        });
        const myjson = await myresponse.json();
        if(myjson.successful){
            window.location.reload();
            alert("Successfully Updated");
        }
    }
    return(
        <Container fluid className={classes.Box}> 
            <div>
                <p>{props.type}:</p> 
                <DraftEditor change={(text)=>updateMessage(text)} text={message}/>
            </div>
            <center>
                <Button variant="success" onClick={()=>handleSubmit(props.id,props.type)}>Update</Button>
            </center>
            <hr/>
            {downloaded.length>0?<Markup content={downloaded} />:null}
        </Container>
    )
}
export default Pages;