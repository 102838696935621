import React,{useState,Fragment, useEffect} from 'react';
import MetaEditHandler from './metaEditHandler';
import {Table} from 'react-bootstrap';
import { GetMetaDetails } from '../../../entities/action/action';
import {useSelector} from 'react-redux';

const MetaDetails = (props)=>{
    const [data,updateData] = useState({});
    const userdata = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        (async()=>{
            const newData = {
                "emailId": userdata.data.key,
                "password": "string",
                "sessionKey": userdata.sessionId
            }
            const response = await GetMetaDetails(newData);
            console.log(response)
            if(response.successful&&response.websiteManagementMetaDetails!==null){
                updateData(response.websiteManagementMetaDetails);
            }
        })()
    },[])
    return(
        <Fragment>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Meta Title</th>
                        <th>Meta Description</th>
                        <th>Meta Keyword</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    Object.keys(data).length>0?
                    <tr>
                        <td>1</td>
                        <td>{data.metaTitle}</td>
                        <td>{data.description}</td>
                        <td>{data.keyword}</td>
                        <td>
                            <MetaEditHandler data={data}/>
                        </td>
                    </tr>:null     
                } 
               
                </tbody>
            </Table>
        </Fragment>
    )
}
export default MetaDetails