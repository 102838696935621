import React, {useState, Fragment,useEffect } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css';
import AddStaff from '../addStaff';
import StaffList from '../staffList';
import StaffView from '../staffView';
import AdminNavbar from '../navbar/adminNavbar';
import AddDesignationDepartment from './addDesignationDepartment'
import { useSelector,useDispatch } from 'react-redux';
import { GetDepartment, GetDesignation } from '../../entities/action/action';

const StaffManagement = (props)=>{
    const [active,updateActive] = useState('1');
    const [departments,updateDepartments] = useState([]);
    const [designations,updateDesignation] = useState([])
    console.log(departments)
  
    const dispatch = useDispatch();
    useEffect(()=>{
        (async()=>{
            const res1 = await GetDepartment();
            updateDepartments(res1);
            const res2 = await GetDesignation();
            updateDesignation(res2);
        })()
    },[])
    const [data,updateData] = useState({});
    const items = [
        {id:"1",type:"Add Staff"},
        {id:"2",type:"Add Department and Designation"},
        {id:"3",type:"Staff List"}
    ]
    const handleClick = (select)=>{
        console.log(select)
        updateData(select);
        updateActive("4");
    }
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Staff Management</h1>  
        <Container fluid>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%',width: '90%'}}> 
                        <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{
                                        backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                        textAlign: 'center',
                                        margin: '0 1px', // Adjust the margin as needed
                                        padding:" 2% 0",
                                        color: 'white',
                                        cursor: 'pointer',
                                        width:"33%",
                                        height:"100%"
                                    }}>
                                    {item.type}
                                    </li>
                                )
                            }
                            {
                                active==="4"?<li 
                                onClick={()=>updateActive("3")}
                                className={classes.Li}
                                style={{backgroundColor:`${active==="4"?'steelblue':'#585858'}`,textAlign:'center'}}>
                                Back
                                </li>:null
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{height:'100%',margin:"2vh 9vw"}}> 
                        {
                            active==="1"?<AddStaff departments={departments} designations={designations}/>:
                            active==="2"?<AddDesignationDepartment departments={departments} designations={designations}/>:
                            active==="3"?<StaffList click={(select)=>handleClick(select)}/>:
                            active==="4"?<StaffView data={data} departments={departments} designations={designations}/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default StaffManagement;