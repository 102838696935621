import React, { useState,useEffect } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import { GetStaffList,FlipStaffStatus } from '../entities/action/action';
import {useSelector} from 'react-redux';
import Page from './pagination/pagination';

const StaffList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
    useEffect(()=>{
        (async()=>{
            const newData = {
                "emailId": userData.data.key,
                "password": "string",
                "sessionKey": userData.sessionId
            }
            const response = await GetStaffList(newData);
            if(response.successful){
                updateDataSet(response.staffs);
            }
        })()
    },[])
    const handleStatus = async(localData)=>{
        //hit the url
        const newData = {
            "status": !localData.activeStatus,
            "loginId": userData.data.key,
            "sessionKey": userData.sessionId,
            "staffId": localData.id
        }
        const response = await FlipStaffStatus(newData);
        if(response.successful){
            let newArray = [...dataSet];
            newArray.map(data=>{
            if(data.id===localData.id){
                if(data.activeStatus===true){
                    data.activeStatus=false;
                }else{
                    data.activeStatus=true;
                }
            }
        })
        updateDataSet(newArray);
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Emp Id</th>
                        <th>Staff Key</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Working Location</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.workDetails.employeeid}</td>
                                <td>{data.workDetails.staffkey}</td>
                                <td>{data.phone}</td>
                                <td>{data.activeStatus===true?
                                    <Button variant="success" onClick={()=>handleStatus(data)}>Active</Button>:
                                    <Button variant="danger" onClick={()=>handleStatus(data)}>Inactive</Button>}
                                </td>
                                <td>{data.workDetails.worklocation}</td>
                                <td className={classes.CenterRow}>
                                    <Button onClick={()=>props.click(data)}>View</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default StaffList;