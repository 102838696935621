import React, { useEffect, useState } from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import DeductionList from './deductionList';

const Deduction = (props)=>{
    const[prophandler,setprophandler] = useState(true);
    const [dataSet,updateDataSet] = useState([]);
    const months = [1,2,3,4,5,6,7,8,9,10,11,12];
    useEffect(()=>{
        (async()=>{
            const newdata = {
                "emailId": "string",
                "password": "string",
                "phoneno": "string",
                "sessionKey": "string"
              }
            const res = await fetch('https://api.zupptravel.com/getTravelDeposit',{
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body:JSON.stringify(newdata)
            })
            const json = await res.json();
            if(json.successful){
                updateDataSet(json.travelDeposits);
            }
        })()
    },[])
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                    const newdata = {
                        "deductionAmount": values.amount,
                        "depositFreq": values.depositeType,
                        "month": values.month,
                        "theme": values.theme,
                        "travelDeductionId": 0,
                        "updatedBy": "string"
                    }
                    const myresponse = await fetch('https://api.zupptravel.com/addTravelDeduction', {
                        method: "post",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(newdata),
                      });
                      const json = await myresponse.json();
                      if(json.successful){
                          alert('Travel Deduction added successfully!')
                          setprophandler(false);
                          
                      }else{
                          alert('Error! Please try again later!')
                      }
                      setprophandler(true)
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicTheme" as={Row}>
                                            <Form.Label column sm="3">Theme:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control 
                                                    as="select"
                                                    name="theme"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.theme}
                                                    required>
                                                        <option value="">--select--</option>
                                                        {
                                                            dataSet.length>0?dataSet.map((item,key)=>(
                                                                <option value={item.theme}>{item.theme}</option>
                                                            )):null
                                                        }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicMonth" as={Row}>
                                            <Form.Label column sm="3">Month:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control 
                                                    as="select"
                                                    name="month"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.month}
                                                    required>
                                                        <option value="">--select--</option>
                                                        {
                                                            months.map((item,key)=>(
                                                                <option value={`Month ${item}`} >Month {item}</option>
                                                            ))
                                                        }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicDepositeType" as={Row}>
                                            <Form.Label column sm="3">Deposit Frequency:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control 
                                                    as="select"
                                                    name="depositeType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.depositeType}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="one-time">one-time</option>
                                                        <option value="monthly">monthly</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAmount" as={Row}>
                                            <Form.Label column sm="3">Enter Amount:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control 
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    min={0}
                                                    step="any"
                                                    name="amount"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.amount}
                                                    required/> 
                                            </Col>
                                        </Form.Group> 
                                        <center>
                                            <Button type="submit" variant="success">Save Travel Deposit</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                        <hr/>
                        <DeductionList prophandler={prophandler}/>
                    </div>            
    )
}
export default Deduction;