import React, { useState } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AddDepartment from './addDepartment';

const AddDesignationDepartment = (props)=>{
    const [active,updateActive] = useState("1");
    return(
        <Container fluid className={classes.Box}>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col xs={6} className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Add Department
                </Col>
                <Col xs={6} className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Add Designation
                </Col>
            </Row>
            {
                active==="1"?<AddDepartment categoryType="Department" data={props.departments}/>:
                active==="2"?<AddDepartment categoryType="Designation" data={props.designations}/>:null
            }
        </Container>  
    )
}
export default AddDesignationDepartment;