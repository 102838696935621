import React, { Fragment,useState,useEffect } from 'react';
import {Modal,Button, Form} from 'react-bootstrap';
import {Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import { GetAPI } from '../entities/action/action';

const AddApi = (props)=>{
    // console.log(props.type)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [success,updateSuccess] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        if(success==true){
            dispatch(GetAPI(props.type))
            setTimeout(()=>{
                updateSuccess(false);
            },5000)
        }
    },[success])
    return(
        <Fragment>
            <Button style={{margin:'5% 1%'}} variant="success" onClick={handleShow}>Add API</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add {props.type} API</Modal.Title>
                </Modal.Header>
                {
                    success?
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>:
                    <Formik
                    initialValues={{provider:'',key:'',accNum:'',dispName:'',gateSalt:''}}
                    onSubmit={
                        async(values,{setSubmitting,resetForm})=>{
                            const newData = {
                                "accountNumber": values.accNum,
                                "apiIp": values.ip,
                                "apiProvider": values.provider,
                                "apiToken": values.token,
                                "apiUrl": values.url,
                                "apiVersion": values.version,
                                "clientId": values.client,
                                "emailId": userData.data.key,
                                "id": 0,
                                "sessionKey": userData.sessionId,
                                "userName": values.userName,
                                "type":props.type
                            }
                            console.log(newData)
                            const myresponse = await fetch("https://api.zupptravel.com/APIManagement/addAPI", {
                                method: "post",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(newData),
                                });
                                const myjson = await myresponse.json();
                                console.log(myjson)
                                if(myjson.successful){
                                    updateSuccess(true);
                                }
                                resetForm();
                        }
                    }>
                        {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Body> 
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>API Provider:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter Provider API" 
                                        name="provider"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.provider}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>API Token:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter API Token" 
                                        name="token"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.token}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicaccNum">
                                        <Form.Label>Account Number:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Account Number" 
                                        name="accNum"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.accNum}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label>User Name:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter User Name" 
                                        name="userName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userName}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVersion">
                                        <Form.Label>API Version:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter API Version" 
                                        name="version"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.version}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicURL">
                                        <Form.Label>API URL:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter API URL" 
                                        name="url"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.url}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicIP">
                                        <Form.Label>API IP:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter API IP" 
                                        name="ip"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ip}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicClient">
                                        <Form.Label>Client ID:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter Client ID" 
                                        name="client"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.client}
                                        required/>
                                    </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    </Modal.Footer>
                                </Form>
                            )
                        }
                    </Formik>
                }
            </Modal>
        </Fragment>
    )
}
export default AddApi;