import React, { useState } from 'react';
import classes from './WebsiteManagement/websiteManagement.module.css';
import { Table,Button,Form } from 'react-bootstrap';
import {Formik} from 'formik';
import MultipleSelect from './multiSelect';
import Image from '../utilities/imageTestApi/image';
import {PincodeGetter} from '../utilities/pincode/pincode';
import {useSelector} from 'react-redux';
import { AddStaffData } from '../entities/action/action';

const StaffView = (props)=>{
    const [edit,updateEdit] = useState(false);
    const [select,updateSelect] = useState(props.data.workDetails.moduleaccess.split(','));
    const [aadharUpload,updateAadharUpload] = useState({});
    const [panUpload,updatePanUpload] = useState({});
    const [cancelChequeUpload,updateCancelCheque] = useState({});
    const [state,setState]=useState(props.data.state);
    const [city,setCity]=useState(props.data.city);
    const userData = useSelector(state=>state.adminAccount.apiResponse);

    const clickHandler = (id)=>{
        const win = window.open(id, '_blank');
        win.focus();
    }
    const handlePickupPincodeChange = async(value)=>{
        if(value.length==6){
            const response = await PincodeGetter(value);
            setCity(response.city_name);
            setState(response.state_name);
        }
    }
    return(
        <> 
        {
            Object.keys(props.data).length>0?
                <Formik
                initialValues={{
                    name:props.data.name,
                    addressline1:props.data.addressline1,
                    addressline2:props.data.addressline2,
                    village:props.data.village,
                    empId:props.data.workDetails.employeeid,
                    email:props.data.email,
                    gender:props.data.gender,
                    city:props.data.city,
                    state:props.data.state,
                    pin:props.data.pincode,
                    phone:props.data.phone,
                    dob:props.data.dateofbirth,
                    designation:props.data.workDetails.designation,
                    location:props.data.workDetails.worklocation,
                    staffType:props.data.workDetails.stafftype,
                    mEmail:props.data.workDetails.managersemail,
                    reportingTo:props.data.workDetails.reportingmanager,
                    doj:props.data.workDetails.doj,
                    department:props.data.workDetails.department,
                    bName:props.data.bankDetails.bankname,
                    accName:props.data.bankDetails.accountholdersname,
                    ifs:props.data.bankDetails.ifsccode,
                    accNum:props.data.bankDetails.accountnumber,
                    acctype:props.data.bankDetails.accounttype,
                    aadhar:props.data.kycDetails.aadharcard,
                    pan:props.data.kycDetails.pancard,
                    location:props.data.workDetails.worklocation,
                    staffkey:props.data.workDetails.staffkey
                }}
                onSubmit={
                    async(values,{setSubmitting,resetForm})=>{
                        setSubmitting(true);
                        let img1 = props.data.kycDetails.aadharcardimg,
                        img2 = props.data.kycDetails.pancardimg,
                        img3 = props.data.kycDetails.canclechequeimg;
                        if(aadharUpload.type!=undefined){
                            img1 = await Image(aadharUpload);
                        }
                        if(panUpload.type!=undefined){
                            img2 = await Image(panUpload);
                        }
                        if(cancelChequeUpload.type!=undefined){
                            img3 = await Image(cancelChequeUpload);
                        }
                        const newData = {
                            "aadharcard": values.aadhar,
                            "aadharcardimg": img1,
                            "accountholdersname": values.accName,
                            "accountnumber": values.accNum,
                            "accounttype": values.acctype,
                            "addressline1": values.addressline1,
                            "addressline2": values.addressline2,
                            "bankname": values.bName,
                            "canclechequeimg": img3,
                            "city": city,
                            "dateofbirth": values.dob,
                            "department": values.department,
                            "designation": values.designation,
                            "doj": values.doj,
                            "email": values.email,
                            "employeeid": props.data.workDetails.employeeid,
                            "gender": values.gender,
                            "ifsccode": values.ifs,
                            "loginEmailId": userData.data.key,
                            "managersemail": values.mEmail,
                            "moduleaccess": select.toString(),
                            "name": values.name,
                            "pancard": values.pan,
                            "pancardimg": img2,
                            "phone": values.phone,
                            "pincode": values.pin,
                            "reportingmanager": values.reportingTo,
                            "sessionkey": userData.sessionId,
                            "staffkey": props.data.workDetails.staffkey,
                            "stafftype": values.type,
                            "state": state,
                            "village": values.village===null?"":values.village,
                            "worklocation": values.location,
                            "activeStatus":props.data.activeStatus,
                            "staffId":props.data.id
                        }
                        console.log(newData);
                        const res = await AddStaffData(newData);
                        console.log(res);
                        if(res.successful){
                            alert("Successfully Updated Record");
                            window.location.reload();
                        }else{
                            alert("Some Error Was Encountered");
                        }
                        setSubmitting(false);
                    }
                }
                >
                    {
                        ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting,resetForm})=>(
                            <Form onSubmit={handleSubmit}> 
                                <div className={classes.Box}>
                                    {
                                        !edit?
                                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                                            <Button variant="success" onClick={()=>updateEdit(true)}>Edit</Button>
                                        </div>:null
                                    }
                                    <center><h4>Personal Details</h4></center>
                                
                                        <Table striped bordered hover responsive>
                                            <tr>
                                                <td><strong>Name: </strong></td> 
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Name" 
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        required
                                                        disabled={!edit}
                                                        />
                                                </td>
                                                <td><strong>Pin Code: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter Pin Code" 
                                                    name="pin"
                                                    onChange={(e)=>handlePickupPincodeChange(e.target.value)}
                                                    onBlur={handleBlur}
                                                    value={values.pin}
                                                    required
                                                    disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Address Line 1: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Address" 
                                                        name="addressline1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.addressline1}
                                                        required
                                                        disabled={!edit}/> 
                                                </td>
                                                <td><strong>Phone Number: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="number" 
                                                        placeholder="Enter Phone Number" 
                                                        name="phone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phone}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Address Line 2: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Address" 
                                                        name="addressline2"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.addressline2}
                                                        required
                                                        disabled={!edit}/> 
                                                </td>
                                                <td><strong>State: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter State" 
                                                        name="state"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.state}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Village: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Village" 
                                                        name="village"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.village}
                                                        disabled={!edit}/>
                                                </td>
                                                <td><strong>Gender: </strong></td>
                                                <td>
                                                    <Form.Control as="select"  
                                                        name="gender"
                                                        onChange={handleChange}
                                                        value={values.gender}
                                                        required
                                                        disabled={!edit}
                                                    >
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                        <option>Others</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>City: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter City" 
                                                        name="city"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.city}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                                <td><strong>DOB: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="date" 
                                                        name="dob"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.dob}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Email ID: </strong></td>
                                                <td colSpan="3">
                                                    <Form.Control 
                                                        type="email" 
                                                        placeholder="Enter Email" 
                                                        name="email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                        </Table>
                                    
                                    <hr/>
                                    <center><h4>Work Details:</h4></center>
                                        <Table striped bordered hover responsive>
                                            <tr>
                                                <td><strong>Employee ID: </strong></td> 
                                                <td>
                                                    <Form.Control 
                                                        readOnly
                                                        type="text" 
                                                        placeholder="Enter Emp ID" 
                                                        name="empId"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.empId}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                                <td><strong>Reporting Manager:</strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Reporting To" 
                                                        name="reportingTo"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.reportingTo}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Designation: </strong></td>
                                                <td>
                                                        <Form.Control 
                                                        as="select" 
                                                        placeholder="Enter Designation" 
                                                        name="designation"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.designation}
                                                        disabled={!edit}
                                                        required
                                                        >
                                                        <option value="">---select---</option>
                                                        {
                                                            props.designations.map((item,key)=>
                                                                <option value={item.designation} key={key}>{item.designation}</option>
                                                            )
                                                        }
                                                        </Form.Control>
                                                </td>
                                                <td><strong>Date of Joining: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="date" 
                                                        name="doj"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.doj}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Work Location: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Work Location" 
                                                        name="location"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.location}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                                <td><strong>Department: </strong></td>
                                                <td> 
                                                    <Form.Control 
                                                    as="select"
                                                    placeholder="Department"   
                                                    name="department"
                                                    onChange={handleChange}
                                                    value={values.department}
                                                    disabled={!edit}
                                                    required
                                                    >
                                                        <option value="">---select---</option>
                                                        {
                                                            props.departments.map((item,key)=>
                                                                <option value={item.department} key={key}>{item.department}</option>
                                                            )
                                                        }

                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Staff Type: </strong></td>
                                                <td>
                                                    <Form.Control as="select"  
                                                    name="staffType"
                                                    onChange={handleChange}
                                                    value={values.staffType}
                                                    disabled={!edit}
                                                    required
                                                    >
                                                        <option value="Full Time">Full Time</option>
                                                        <option value="Part Time">Part Time</option>
                                                        <option value="Contract">Contract</option>
                                                    </Form.Control>
                                                </td>
                                                <td><strong>Manager's Email: </strong></td>
                                                <td> 
                                                    <Form.Control 
                                                        type="email" 
                                                        placeholder="Enter Email" 
                                                        name="mEmail"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.mEmail}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Staff Key: </strong></td> 
                                                <td colSpan="3">
                                                    <Form.Control 
                                                        readOnly
                                                        type="text" 
                                                        placeholder="Enter Staff Key" 
                                                        name="staffkey"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.staffkey}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Module Access: </strong></td>
                                                <td colSpan="3">
                                                    <MultipleSelect type="7" val={select} selectedValue={(selected)=>updateSelect(selected)} disable={edit?false:true}/>
                                                </td>
                                            </tr>
                                        </Table>
                                    <hr/>
                                    <center><h4>Bank Details:</h4></center>
                                    <Table striped bordered hover responsive>
                                            <tr>
                                                <td><strong>Bank Name: </strong></td> 
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Bank Name" 
                                                        name="bName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.bName}
                                                        required 
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Account Holder's Name: </strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Account Holder's Name" 
                                                        name="accName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.accName}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Account Number: </strong></td>
                                                <td> 
                                                    <Form.Control 
                                                        type="number" 
                                                        placeholder="Enter Account Number" 
                                                        name="accNum"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.accNum}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Account Type: </strong></td>
                                                <td> 
                                                    <Form.Control
                                                        type="text" 
                                                        readOnly
                                                        name="acctype"
                                                        onChange={handleChange}
                                                        value={values.acctype}
                                                        required
                                                        disabled={!edit}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>IFS Code:</strong></td>
                                                <td>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter IFS Code" 
                                                        name="ifs"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.ifs}
                                                        required
                                                        disabled={!edit}/>
                                                </td>
                                            </tr>
                                        </Table>
                                        <hr/>
                                        <center><h4>KYC Details:</h4></center>
                                    <Table striped bordered hover responsive>
                                            <tr>
                                                <td><strong>Aadhar Number: </strong></td> 
                                                <td colSpan="2">
                                                <Form.Control 
                                                    type="number" 
                                                    placeholder="12-Digit" 
                                                    name="aadhar"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.aadhar}
                                                    disabled={!edit}
                                                    required/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Pan Card Number: </strong></td>
                                                <td colSpan="2">
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Pan Card Number" 
                                                        name="pan"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.pan}
                                                        disabled={!edit}
                                                        required/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Update Aadhar Upload: </strong></td>
                                                <td> 
                                                    <Form.Control 
                                                        type="file" 
                                                        name="aadharUpload"
                                                        // onChange={(e)=>updateAadharUpload(e.target.files[0])}
                                                        disabled={true}
                                                        readOnly
                                                        />
                                                </td>
                                                <td>
                                                    <Button onClick={()=>clickHandler(props.data.kycDetails.aadharcardimg)}>Open</Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Update PAN Upload: </strong></td>
                                                <td> 
                                                    <Form.Control 
                                                    type="file" 
                                                    name="panUpload"
                                                    // onChange={(e)=>updatePanUpload(e.target.files[0])}
                                                    disabled={true}
                                                    readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <Button onClick={()=>clickHandler(props.data.kycDetails.pancardimg)}>Open</Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Update Cancel Cheque Upload:</strong></td>
                                                <td>
                                                    <Form.Control 
                                                    type="file" 
                                                    name="cancelChequeUpload"
                                                    onChange={(e)=>updateCancelCheque(e.target.files[0])}
                                                    disabled={!edit}
                                                    />
                                                </td>
                                                <td>
                                                    <Button onClick={()=>clickHandler(props.data.kycDetails.canclechequeimg)}>Open</Button>
                                                </td>
                                            </tr>
                                        </Table>
                                        {
                                            edit?
                                            <center>
                                                <Button variant="secondary" style={{margin:'2% 2%'}} onClick={()=>{updateEdit(false); resetForm()}}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary" style={{margin:'2% 2%'}} type="submit" disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                            </center>:null
                                        }
                                    </div>    
                            </Form>
                              )
                            }
                </Formik>:<h2>No Record found</h2>
        }    
        </>
    )
}
export default StaffView;