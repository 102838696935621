import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import {
  GetSightseeingList,
  GetActiveCoupon,
  DeleteSightseeing,
} from "../../entities/action/action";
import { useSelector } from "react-redux";
import Page from "../pagination/pagination";

const SightseeingList = (props) => {
  console.log(props);
  const [dataSet, updateDataSet] = useState([]);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);
  useEffect(() => {
    (async () => {
      let res = await GetSightseeingList();
      console.log(res);
      if (res?.success) {
        let arr = res?.sightSeeings;
        if (arr) {
          updateDataSet(arr);
        }
      }
    })();
  }, []);
  const handleDelete = async (id) => {
    console.log(id);
    const newData = {
      emailId: userData.data.key,
      sessionKey: userData.sessionId,
      sightSeeingId: id,
    };
    console.log(newData);
    const res = await DeleteSightseeing(newData);
    console.log(res);
    if (res.successful) {
      alert("Successfully Deleted");
      // window.location.reload();
    }
  };
  const handleclick = (data) => {
    console.log(data);
    props.mydata(data);
    props.click();
  };
  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Sightseeing Name</th>
            <th>Sightseeing Type</th>
            <th>Sightseeing City</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.sightSeeingName}</td>
                  <td>{data.sightSeeingType}</td>
                  <td>{data.city}</td>
                  <td className={classes.CenterRow}>
                    <Button onClick={() => handleclick(data)}>View</Button>
                    <Button style={{ marginLeft: "5px" }} variant="secondary">
                      Unhide
                    </Button>
                    <Button
                      style={{ marginLeft: "5px" }}
                      onClick={() => handleDelete(data.id)}
                      variant="danger"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default SightseeingList;
