export const HandleDeleteAPI = async(data)=>{
    const response = await fetch("https://api.zupptravel.com/APIManagement/deleteAPI",{
        method:"post",
        headers: {
            "Content-Type": "application/json",
          },
        body:JSON.stringify(data)
    });
    const json = await response.json();
    return json.successful;
}