import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import {DeleteDepartment,DeleteDesignation} from '../../entities/action/action';

const DepartmentDesignationList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    useEffect(()=>{
        if(props.data!=undefined){
            updateDataSet(props.data);
        }
    },[props.data])
    
    const HandleDelete = async(id)=>{
        let response;
        if(props.type=="Department"){
            response = await DeleteDepartment(id);
        }else{
            response = await DeleteDesignation(id);
        }
        if(response.status==200){
            alert("Successfully Removed");
            window.location.reload();
        }else{
            alert("Not Present or already Removed Please Refresh Your Screen");
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>{props.type}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data[props.type.toLowerCase()]}</td>
                                <td className={classes.CenterRow}>
                                    <Button 
                                        style={{margin:'auto 1%'}} 
                                        variant="danger"
                                        onClick={()=>HandleDelete(data.id)}
                                    >Delete</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default DepartmentDesignationList;