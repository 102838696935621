import React, { Fragment, useState } from 'react';
import {Col,Row,Container, Button,Table,DropdownButton} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import AddApi from './AddApi';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAPI } from '../entities/action/action';
import { HandleDeleteAPI } from '../utilities/Api/api';
import UpdateApi from './updateApi';
import RequestRecharge from './requestRecharge';

const API = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const apiData = useSelector(state=>state.account.apiData);
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(GetAPI(props.type))
    },[props.val])
    useEffect(()=>{
        updateDataSet(apiData)
    },[apiData])
    const handleDelete = async(id)=>{
        const data = {
            "id": id,
            "loginEmailId": userData.data.key,
            "sessionId": userData.sessionId
        }
        const res = await HandleDeleteAPI(data);
        if(res==true){
            dispatch(GetAPI(props.type));
        }
    }
    return(
        <Container fluid>
            <Row>
                {
                    dataSet.length<=0?
                    <Fragment>
                        <Col xs={9}></Col>
                        <Col xs={3}>
                            <div className={classes.Center}>
                                <AddApi type={props.type} val={props.val}/>
                            </div>
                        </Col>
                    </Fragment>:null
                }
            </Row>
            <Fragment> 
                <Table striped bordered hover>
                    <thead>
                        <tr style={{fontSize:'12px'}}>
                            <th>S.No:</th>
                            <th>API Provider:</th>
                            <th>API Token:</th>
                            <th>API URL:</th>
                            <th>Client ID:</th> 
                            <th>User Name:</th>
                            <th>Account Number:</th>
                            <th>API IP:</th>
                            <th>API Version:</th>
                            <th>Action:</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    dataSet.length>0?dataSet.map((data,key)=> 
                            <tr key={key}>
                                <td>{data.id}</td>
                                <td>{data.apiProvider}</td>
                                <td>{data.apitoken}</td>
                                <td>{data.apiUrl}</td>
                                <td>{data.apiClientID}</td>
                                <td>{data.apiUserName}</td>
                                <td>{data.accountNumber}</td>
                                <td>{data.apiIP}</td>
                                <td>{data.apiVersion}</td>
                                <td> 
                                    <DropdownButton id="dropdown-variants-Success" title="Actions">
                                        <div className={classes.Center}> 
                                            {
                                                key===0?<AddApi type={props.type} val={props.val}/>:null
                                            }
                                            <UpdateApi type={props.type} val={props.val} data={data}/>
                                            <Button variant="danger" style={{margin:'5% 1%'}} onClick={()=>handleDelete(data.id)}>Delete</Button>
                                            <RequestRecharge type={props.type}/>
                                        </div>
                                    </DropdownButton>
                                </td>
                            </tr>
                    ):<h2>No API Found</h2>  
                    } 
                    </tbody>     
                </Table>            
            </Fragment>    
        </Container>
    )    
}
export default API;