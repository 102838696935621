import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import { GetCurrencyList, DeleteCurrency } from "../../entities/action/action";
import { useSelector } from "react-redux";
import Page from "../pagination/pagination";

const CurrencyList = (props) => {
  const data = useSelector((state) => state.adminAccount.apiResponse);
  console.log(data);
  const [dataSet, updateDataSet] = useState([]);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  console.log(userData);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);

  const [update, setUpdate] = useState(false);

  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);
  console.log(count);
  useEffect(() => {
    (async () => {
      const res = await GetCurrencyList(data);

      console.log(res);
      if (res.successful) {
        updateDataSet(res.currencys);
      }
    })();
  }, [update]);
  console.log(dataSet);
  const handleDelete = async (id) => {
    const newData = {
      id: id,
      loginId: userData.data.key,
      sessionKey: userData.sessionId,
    };
    const res = await DeleteCurrency(newData);
    console.log(res);
    if (res.successful) {
      alert("Successfully Deleted");
      const callback = (prev) => {
        return !prev;
      };
      setUpdate(callback);
    }
  };
  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Currency Name</th>
            <th>Currency Code</th>
            <th>Country Name</th>
            <th>Currency Value</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.code}</td>
                  <td>{data.countryName}</td>
                  <td>{data.value}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      onClick={(e) => {
                        handleDelete(data.id);
                      }}
                      style={{ margin: "auto 1%" }}
                      variant="danger"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default CurrencyList;
