import React, { useState, Fragment } from 'react';
import { Col, Button, Form, Container, Row, Table } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import AddAddress from './addAddress'
import EditAddress from './editAddressModal';
import {useSelector} from 'react-redux';
import {HandleDeleteAddressCustomer} from '../../entities/action/action';

export default function CustomerProfile(props) {
    console.log(props.data)
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const [active, updateActive] = useState("1");
    const [modalShow1, setModalShow1] = React.useState(false);
    const [edit, updateEdit] = useState(false);
    const handleDelete = async(id)=>{
        const newData = {
            "id": id,
            "loginEmailId": userData.data.key,
            "sessionId": userData.sessionId
        }
        const res = await HandleDeleteAddressCustomer(newData);
        if(res.successful){
            alert("Successfully Deleted");
            window.location.reload();
        }
    }
    return (
        <Fragment>
            <Container fluid className={classes.Box}>
                <Row style={{ margin: '0 0 2% 0', textAlign: 'center', cursor: 'pointer' }}>
                    <Col md={6} xs={12} className={classes.Col}
                        style={{ backgroundColor: `${active === '1' ? 'steelblue' : '#585858'}`, color: 'white', padding: '1%' }}
                        onClick={() => updateActive("1")}>
                        Personal
                </Col>
                    <Col md={6} xs={12} className={classes.Col}
                        style={{ backgroundColor: `${active === '2' ? 'steelblue' : '#585858'}`, color: 'white', padding: '1%' }}
                        onClick={() => updateActive("2")}>
                        Address
                </Col>

                </Row>
                {
                    active === "1" ?
                        <Fragment>
                            <Container fluid>
                                <div>
                                    <center><h2 style={{marginBottom:'15px'}}>Personal Info</h2></center>
                                    <Table striped bordered hover responsive>
                                        <tr>
                                            <td><strong>Name: </strong></td>
                                            <td contentEditable={edit}>{props.data.firstName} {props.data.lastName}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Phone Number: </strong></td>
                                            <td contentEditable={edit}>{props.data.phoneNumber} </td>
                                        </tr>

                                        <tr>

                                            <td><strong>Gender: </strong></td>
                                            <td contentEditable={edit}>{props.data.gender} </td>
                                        </tr>
                                        <tr>

                                            <td><strong>DOB: </strong></td>
                                            <td contentEditable={edit}>{props.data.dob} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Email ID: </strong></td>
                                            <td contentEditable={edit}>{props.data.emailId} </td>

                                        </tr>
                                        <tr><td><strong>Profession: </strong></td>
                                            <td contentEditable={edit}>{props.data.profession} </td></tr>
                                        <tr>
                                            <td><strong>Social Profile: </strong></td>
                                            <td contentEditable={edit} colSpan="6">{props.data.socialProfile} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Hobbies: </strong></td>
                                            <td contentEditable={edit} colSpan="6">{props.data.hobbies} </td>
                                        </tr>
                                    </Table>
                                </div>
                            </Container>
                        </Fragment> :

                        active === "2" ?
                            <Fragment>
                                <Container fluid>
                                    <div>
                                        <div style={{display:'flex',justifyContent:'space-between',marginBottom:'15px'}}>
                                            <h3>Address Info</h3>
                                            <Button variant="primary" onClick={() => setModalShow1(true)}>
                                                Add Address
                                            </Button>
                                        </div>
                                        <AddAddress
                                            show={modalShow1}
                                            onHide={() => setModalShow1(false)}
                                            data={props.data}
                                        />
                                        <Table striped bordered hover responsive>
                                            <tr>
                                                <td><strong>Address Line 1: </strong></td>
                                                <td><strong>Address Line 2: </strong></td>
                                                <td><strong>State: </strong></td>
                                                <td><strong>Village: </strong></td>
                                                <td><strong>City: </strong></td>
                                                <td><strong>Pin Code: </strong></td>
                                                <td><strong>Action: </strong></td>
                                            </tr>
                                            {
                                                props.data.customerAddresses.map((address,key)=>
                                                    <tr key={key}>
                                                        <td>{address.addressLine1} </td> 
                                                        <td>{address.addressLine2} </td>
                                                        <td>{address.state} </td>
                                                        <td>{address.village}</td>
                                                        <td>{address.city} </td>
                                                        <td>{address.pinCode}</td>
                                                        <td>
                                                            <div style={{display:'flex',justifyContent:'center'}}>
                                                                <EditAddress address={address} data={props.data}/>
                                                                <Button style={{marginLeft:'10px'}} variant="danger" onClick={()=>handleDelete(address.id)}>Delete</Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </Table>    
                                    </div>
                                </Container>
                            </Fragment> : null
                }
            </Container>

        </Fragment>
    );
}