import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
// import {GetActiveCoupon,DeleteCoupon} from '../../entities/action/action';
import { useSelector } from "react-redux";
import Page from "../pagination/pagination";
import { DeleteAirline } from "../../entities/action/action";

const LocationList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [update, setUpdate] = useState(false);
  console.log(data);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet?.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);
  useEffect(() => {
    (async () => {
      const myresponse = await fetch(`https://api.zupptravel.com/getAirline`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: data.data.key,
          password: "string",
          phoneno: "string",
          sessionKey: data.sessionId,
        }),
      });
      const json = await myresponse.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.airlines);
      }
    })();
  }, [update]);
  console.log(dataSet);

  const handleDelete = async (id) => {
    const newData = {
      airlineCode: data.airlineCode,
      emailId: data.data.key,
      airlineId: id,
      sessionKey: data.sessionId,
      providerType: data.providerType,
    };
    const res = await DeleteAirline(newData);
    if (res.successful) {
      alert("Successfully Deleted");
      const callback = (prev) => {
        return !prev;
      };
      setUpdate(callback);
    }
  };

  //   const handleDelete = async (id) => {
  //     (async () => {
  //       const myresponse = await fetch(
  //         `https://api.zupptravel.com/deleteLocationManagement`,
  //         {
  //           method: "post",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             id: id,
  //             loginId: userData.data.key,
  //             sessionKey: userData.sessionId,
  //           }),
  //         }
  //       );
  //       const json = await myresponse.json();
  //       if (json.successful) {
  //         alert("Successfully Deleted");
  //         const callback = (prev) => {
  //           return !prev;
  //         };
  //         setUpdate(callback);
  //       }
  //     })();
  //   };

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Code</th>
            <th>Helpline Email</th>
            <th>Helpline Number</th>
            <th>Name</th>
            <th>Provider Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.airlineCode}</td>
                  <td>{data.airlineHelplineEmail}</td>
                  <td>{data.airlineHelplineNo}</td>
                  <td>{data.airlineName}</td>
                  <td>{data.providerType}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      onClick={(e) => {
                        handleDelete(data.id);
                      }}
                      style={{ margin: "auto 1%" }}
                      variant="danger"
                    >
                      Delete
                    </Button>
                    {/* <Button>View</Button> */}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default LocationList;
