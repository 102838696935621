import React, { Fragment, useState, useEffect } from 'react';
import {Col,Table, DropdownButton,Container, Button} from 'react-bootstrap';
import classes from '../websiteManagement.module.css';
import AddAddress from './addAddressModal';
import { GetContactInfo,ContactInfoDelete } from '../../../entities/action/action';
import {useSelector} from 'react-redux';


const ContactInfo = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const userdata = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        (async()=>{
            const newData = {
                "emailId": userdata.data.key,
                "password": "string",
                "sessionKey": userdata.sessionId
            }
            const response = await GetContactInfo(newData);
            console.log(response)
            if(response.successful&&response.websiteManagementContactInfo!==null){
                updateDataSet([response.websiteManagementContactInfo]);
            }
        })()
    },[])
    const handleDelete = async(id)=>{
        const data = {
            "id": id,
            // "loginid": userdata.data.key,
            // "sessionKey": userdata.sessionId
        }
        const res = await ContactInfoDelete(data);
        if(res.successful){
            alert("Successfully Deleted");
            window.location.reload();
        }else{
            alert("Something went wrong!!");
        }
    }
    return(
        <Container fluid>
                {
                    dataSet.length===0?
                    <Fragment>
                        <Col xs={9}></Col>
                        <Col xs={3}>
                            <div className={classes.Center}>
                                <AddAddress/>
                            </div>
                        </Col>
                    </Fragment>:null
                }
                <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Address Line 1</th>
                        <th>Address Line 2</th>
                        <th>Village</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Pincode</th>
                        <th>Phone Number</th>
                        <th>Email ID</th>
                        <th>CIN</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    dataSet.length>0?dataSet.map((data,key)=>
                    <tr key={key}>
                        <td>{key+1}</td>
                        <td>{data.addressLine1}</td>
                        <td>{data.addressLine2}</td>
                        <td>{data.village}</td>
                        <td>{data.city}</td>
                        <td>{data.state}</td>
                        <td>{data.pincode}</td>
                        <td>{data.phoneNumber}</td>
                        <td>{data.webEmailId}</td>
                        <td>{data.cin}</td>
                        <td>
                            <DropdownButton id="dropdown-variants-Success" title="Actions">
                                <div className={classes.Center}>
                                    <Button variant="danger" onClick={()=>handleDelete(data.id)}>Delete</Button>
                                </div>
                            </DropdownButton>
                        </td>
                    </tr>       
                    ):null
                }
             </tbody>
            </Table>
        </Container>
    )
}
export default ContactInfo;