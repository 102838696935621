import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import AddConditionList from './addConditionList';

const AddConditions = (props)=>{
    const months = [1,2,3,4,5,6,7,8,9,10,11,12];
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                    const newdata = {
                        "benchmarkValue": values.benchmarkValue,
                        "depositFrequency": values.depositeType,
                        "emailId": "string",
                        "endDate": values.to,
                        "id": 0,
                        "interestAmount": values.interestAmount,
                        "interestRate": values.interestPercentage,
                        "minimumAmount": values.amount,
                        "sessionKey": "string",
                        "startDate": values.from
                    }
                    const myresponse = await fetch('https://api.zupptravel.com/addTravelDepositCondition', {
                        method: "post",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(newdata),
                      });
                      const json = await myresponse.json();
                      if(json.successful){
                          alert('Travel Condition added successfully!')
                          
                      }else{
                          alert('Error! Please try again later!')
                      }
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">Month Range:</Form.Label>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="select"
                                                placeholder="From" 
                                                name="from"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.from}
                                                required>
                                                 <option value="">--select--</option>
                                                        {
                                                            months.map((item,key)=>(
                                                                <option value={`Month ${item}`} >Month {item}</option>
                                                            ))
                                                        }
                                                </Form.Control>
                                            </Col>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="select" 
                                                min={0}
                                                placeholder="To" 
                                                name="to"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.to}
                                                required>
                                                
                                                <option value="">--select--</option>
                                                        {
                                                            months.map((item,key)=>(
                                                                <option value={`Month ${item}`} >Month {item}</option>
                                                            ))
                                                        }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicDepositeType" as={Row}>
                                            <Form.Label column sm="2">Deposit Frequency:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select"
                                                    name="depositeType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.depositeType}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="one-time">one-time</option>
                                                        <option value="monthly">monthly</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAmount" as={Row}> 
                                            <Form.Label column sm="2">Amount:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Amount" 
                                                name="amount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.amount}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicBenchmarkValue" as={Row}> 
                                            <Form.Label column sm="2">Benchmark Value:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Benchmark Value" 
                                                name="benchmarkValue"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.benchmarkValue}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicInterestPercentage" as={Row}> 
                                            <Form.Label column sm="2">Interest Percentage:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Interest Percentage" 
                                                name="interestPercentage"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.interestPercentage}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicInterestAmount" as={Row}> 
                                            <Form.Label column sm="2">Interest Amount:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Interest Amount" 
                                                name="interestAmount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.interestAmount}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Save Travel Deposit</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                        <hr/>
                        <AddConditionList/>
                    </div>            
    )
}
export default AddConditions;