import React, { useState, useEffect } from 'react';
import {Col,Button,Form, Container, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import { customAlphabet } from 'nanoid';
import classes from './WebsiteManagement/websiteManagement.module.css';
import MultipleSelect from './multiSelect';
import {PincodeGetter} from '../utilities/pincode/pincode';
import {useSelector} from 'react-redux';
import Image from '../utilities/imageTestApi/image';
import { AddStaffData } from '../entities/action/action';

const AddStaff = (props)=>{
    const [active,updateActive] = useState("1");
    const [select,updateSelect] = useState([]);
    const nanoid = customAlphabet('1234567890', 12);
    const [id,updateId] = useState('');
    const [key,updateKey] = useState('');
    const [state,setState]=useState('');
    const [city,setCity]=useState('');
    const [pincode,updatePincode] = useState('');
    const [aadharUpload,updateAadharUpload] = useState({});
    const [panUpload,updatePanUpload] = useState({});
    const [cancelChequeUpload,updateCancelCheque] = useState({});
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        updateId(nanoid());
        updateKey(nanoid());
    },[])
    const handlePickupPincodeChange = async(value)=>{
        updatePincode(value);
        if(value.length==6){
            const response = await PincodeGetter(value);
            setCity(response.city_name);
            setState(response.state_name);
        }
    }
    return(
        <Container fluid className={classes.Box}>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col md={3} xs={12} className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Personal
                </Col>
                <Col md={3} xs={12} className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Work Details
                </Col>
                <Col md={3} xs={12} className={classes.Col}
                style={{backgroundColor:`${active==='3'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("3")}>
                    Bank Details
                </Col>
                <Col md={3} xs={12} className={classes.Col}
                style={{backgroundColor:`${active==='4'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("4")}>
                    KYC
                </Col>
            </Row>
            <Formik
            initialValues={{name:'',email:'',gender:'Male',staffType:'Full Time',pan:'',accName:'',accType:'Saving'}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    setSubmitting(true);
                    const img1 = await Image(aadharUpload);
                    const img2 = await Image(panUpload);
                    const img3 = await Image(cancelChequeUpload);
                    const newData = {
                        "aadharcard": values.aadhar,
                        "aadharcardimg": img1,
                        "accountholdersname": values.accName,
                        "accountnumber": values.accNum,
                        "accounttype": values.accType,
                        "addressline1": values.addressline1,
                        "addressline2": values.addressline2,
                        "bankname": values.bName,
                        "canclechequeimg": img3,
                        "city": city,
                        "dateofbirth": values.dob,
                        "department": values.department,
                        "designation": values.designation,
                        "doj": values.doj,
                        "email": values.email,
                        "employeeid": id,
                        "gender": values.gender,
                        "ifsccode": values.ifs,
                        "loginEmailId": userData.data.key,
                        "managersemail": values.mEmail,
                        "moduleaccess": select.toString(),
                        "name": values.name,
                        "pancard": values.pan,
                        "pancardimg": img2,
                        "phone": values.phone,
                        "pincode": pincode,
                        "reportingmanager": values.reportingTo,
                        "sessionkey": userData.sessionId,
                        "staffkey": key,
                        "stafftype": values.staffType,
                        "state": state,
                        "village": values.village,
                        "worklocation": values.location
                    }
                    console.log(newData);
                    const response = await AddStaffData(newData);
                    if(response.successful){
                        alert("Successfully Added Staff");
                        window.location.reload();
                    }
                    setSubmitting(false);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                    <div style={{display:active==="1"?"block":"none"}}>              
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label>Enter Name:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter Name" 
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            required/>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAddress">
                                            <Form.Label>Address Line 1:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter Address" 
                                            name="addressline1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.addressline1}
                                            required/>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAddress">
                                            <Form.Label>Address Line 2:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter Address" 
                                            name="addressline2"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.addressline2}
                                            required/>
                                        </Form.Group>
                                        <Row> 
                                        <Col md={6} xs={12} >     
                                            <Form.Group controlId="formBasicPin">
                                                <Form.Label>Pin Code:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Pin Code" 
                                                name="pincode"
                                                onChange={(e)=>handlePickupPincodeChange(e.target.value)}
                                                value={pincode}
                                                required/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Form.Group controlId="formBasicCity">
                                                <Form.Label>City:</Form.Label>
                                                <Form.Control 
                                                readOnly
                                                type="text" 
                                                placeholder="Enter City" 
                                                name="city"
                                                value={city}
                                                required/>
                                            </Form.Group>
                                        </Col>    
                                        </Row>
                                        <Row>
                                        <Col md={6} xs={12} >   
                                            <Form.Group controlId="formBasicState">
                                                <Form.Label>State:</Form.Label>
                                                <Form.Control
                                                readOnly 
                                                type="text" 
                                                placeholder="Enter State" 
                                                name="state"
                                                value={state}
                                                required/>
                                            </Form.Group>
                                        </Col>    
                                        <Col md={6} xs={12}>
                                            <Form.Group controlId="formBasicVillage">
                                                <Form.Label>Village:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Village" 
                                                name="village"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.village}
                                                />
                                            </Form.Group>
                                        </Col> 
                                        </Row>
                                        <Row>
                                        <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email ID:</Form.Label>
                                            <Form.Control 
                                            type="email" 
                                            placeholder="Enter Email" 
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            required/>
                                        </Form.Group> 
                                        </Col>
                                        <Col md={6} xs={12}> 
                                        <Form.Group controlId="formBasicPhone">
                                            <Form.Label>Phone Number:</Form.Label>
                                            <Form.Control 
                                            type="number" 
                                            placeholder="Enter Phone Number" 
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            required/>
                                        </Form.Group>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicDob">
                                            <Form.Label>Date of Birth:</Form.Label>
                                            <Form.Control 
                                            type="date" 
                                            name="dob"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.dob}
                                            required/>
                                        </Form.Group> 
                                        </Col>
                                        <Col md={6} xs={12} > 
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Gender:</Form.Label>
                                            <Form.Control as="select"  
                                            name="gender"
                                            onChange={handleChange}
                                            value={values.gender}
                                            required
                                            >
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                        </Row>
                                        <center><Button style={{margin:'2%'}} onClick={()=>updateActive("2")}>Next</Button></center> 
                                    </div>
                                    <div style={{display:active==="2"?"block":"none"}}> 
                                       
                                        <Row>
                                        <Col md={4} xs={12} > 
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Department:</Form.Label>
                                            <Form.Control 
                                            as="select"
                                            placeholder="Department"   
                                            name="department"
                                            onChange={handleChange}
                                            value={values.department}
                                            required
                                            >
                                                <option value="">---select---</option>
                                                {
                                                    props.departments.map((item,key)=>
                                                        <option value={item.department} key={key}>{item.department}</option>
                                                    )
                                                }

                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} > 
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Designation:</Form.Label>
                                                <Form.Control 
                                                as="select" 
                                                placeholder="Enter Designation" 
                                                name="designation"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.designation}
                                                required
                                                >
                                                <option value="">---select---</option>
                                                {
                                                    props.designations.map((item,key)=>
                                                        <option value={item.designation} key={key}>{item.designation}</option>
                                                    )
                                                }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} > 
                                            <Form.Group controlId="formBasicPin">
                                                <Form.Label>Employee ID:</Form.Label>
                                              
                                                <Form.Control plaintext readOnly value={id} />
                                            
                                            </Form.Group>
                                        </Col>
                                        
                                        </Row>
                                        <Row>
                                            <Col md={6} xs={12}>
                                            <Form.Group controlId="formBasicAddress">
                                            <Form.Label>Work Location:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Work Location" 
                                            name="location"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.location}
                                            required/>
                                        </Form.Group>
                                            </Col>
                                            <Col md={6} xs={12} > 
                                        <Form.Group controlId="exampleForm.ControlSelect10">
                                            <Form.Label>Staff Type:</Form.Label>
                                            <Form.Control as="select"  
                                                name="staffType"
                                                onChange={handleChange}
                                                value={values.staffType}
                                                required
                                            >
                                                <option value="">--select--</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Contract">Contract</option>
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                        </Row>
                                        
                                       
                                        <Row>
                                        <Col md={6} xs={12} > 
                                            <Form.Group controlId="formPlaintextID">
                                                <Form.Label>
                                                Staff Key:
                                                </Form.Label>
                                               
                                                <Form.Control plaintext readOnly value={key} />
                                             
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicDob">
                                            <Form.Label>Date of Joining:</Form.Label>
                                            <Form.Control 
                                            type="date" 
                                            name="doj"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.doj}
                                            required/>
                                        </Form.Group> 
                                    </Col>
                                       
                                        </Row>
                                        <Row>
                                        <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Manager's Email ID:</Form.Label>
                                            <Form.Control 
                                            type="email" 
                                            placeholder="Enter Email" 
                                            name="mEmail"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mEmail}
                                            required/>
                                        </Form.Group> 
                                        </Col>
                                        <Col md={6} xs={12} > 
                                            <Form.Group controlId="formBasicPin">
                                                <Form.Label>Reporting Manager:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Reporting To" 
                                                name="reportingTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.reportingTo}
                                                required/>
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                       
                                   
                                    </Row>
                                    <MultipleSelect type="1" selectedValue={(selected)=>updateSelect(selected)}/>
                                    <center>
                                        <Button onClick={()=>updateActive("1")} variant="secondary" style={{margin:'2%'}}>Back</Button>
                                        <Button onClick={()=>updateActive("3")} style={{margin:'2%'}}>Next</Button>
                                    </center>
                                </div> 
                                <div style={{display:active==="3"?"block":"none"}}>                                  
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>Bank Name:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter Bank Name" 
                                        name="bName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bName}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label>Account Holder's Name:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Account Holder's Name" 
                                        name="accName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.accName}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicAccNumber">
                                        <Form.Label>Account Number:</Form.Label>
                                        <Form.Control 
                                        type="number" 
                                        placeholder="Enter Account Number" 
                                        name="accNum"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.accNum}
                                        required/>
                                    </Form.Group>
                                    <Row> 
                                    <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicAccType">
                                            <Form.Label>Account Type:</Form.Label>
                                            <Form.Control as="select"  
                                                name="acctype"
                                                onChange={handleChange}
                                                value={values.acctype}
                                                required
                                            >
                                                <option value="Saving">Saving</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicPin">
                                            <Form.Label>IFS Code:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter IFS Code" 
                                            name="ifs"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ifs}
                                            required/>
                                        </Form.Group>
                                    </Col>    
                                    </Row>
                                    <center>
                                    <Button onClick={()=>updateActive("2")} variant="secondary" style={{margin:'2%'}}>Back</Button>
                                    <Button onClick={()=>updateActive("4")} style={{margin:'2%'}}>Next</Button>
                                     </center>
                                </div> 
                                <div style={{display:active==="4"?"block":"none"}}> 
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label>Aadhar Card Number:</Form.Label>
                                        <Form.Control 
                                        type="number" 
                                        placeholder="12-Digit" 
                                        name="aadhar"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.aadhar}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicAccNumber">
                                        <Form.Label>Pan Card Number:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter Pan Card Number" 
                                        name="pan"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.pan}
                                        required/>
                                    </Form.Group>
                                    <Row style={{margin:0}}> 
                                    <Col xl={4} lg={12} className={classes.Col}>
                                        <Form.Group controlId="formBasicAccType">
                                            <Form.Label>Upload Aadhar:</Form.Label>
                                            <Form.Control 
                                            type="file" 
                                            name="aadharUpload"
                                            onChange={(e)=>updateAadharUpload(e.target.files[0])}
                                            required/>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4} lg={12} className={classes.Col}> 
                                        <Form.Group controlId="formBasicPin">
                                            <Form.Label>Upload Pan Card:</Form.Label>
                                            <Form.Control 
                                            type="file" 
                                            name="panUpload"
                                            onChange={(e)=>updatePanUpload(e.target.files[0])}
                                            required/>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4} lg={12} className={classes.Col}> 
                                        <Form.Group controlId="formBasicCheque">
                                            <Form.Label>Upload Cancel Cheque:</Form.Label>
                                            <Form.Control 
                                            type="file" 
                                            name="cancelChequeUpload"
                                            onChange={(e)=>updateCancelCheque(e.target.files[0])}
                                            required/>
                                        </Form.Group>
                                    </Col>
                                    </Row>    
                                    <center>
                                    <Button onClick={()=>updateActive("3")} variant="secondary" style={{margin:'2% 2%'}}>
                                        Back
                                    </Button>
                                    <Button variant="primary" style={{margin:'2% 2%'}} type="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    </center>
                                    </div>
                                </Form>
                            )
                        }
            </Formik>
        </Container>
    )
}
export default AddStaff;