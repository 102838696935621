
import { Table, Button, Row, Col, Container } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import React, { Fragment, useState, useEffect } from 'react';
import classesX from '../Dashboard/dashboard.module.css';
import Page from '../pagination/pagination';
import { GetCustomers } from '../../entities/action/action';

const CustomerList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const [search, updateSearch] = useState(''); // State for the search query
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;

  // Filter the dataSet based on the search query
  const filteredData = dataSet.filter((data) => {
    return (
      data.customerId.toLowerCase().includes(search.toLowerCase()) ||
      data.emailId.toLowerCase().includes(search.toLowerCase()) ||
      data.phoneNumber.includes(search)
    );
  });
// Function to handle the search button click
const handleFilter = () => {
    // Convert the search query to lowercase for case-insensitive search
    const searchText = search.toLowerCase();
  
    // Filter the dataSet based on the search query
    const filteredData = dataSet.filter((data) => {
      // Check if any of the fields contain the search text
      return (
        data.customerId.toLowerCase().includes(searchText) ||
        data.emailId.toLowerCase().includes(searchText) ||
        data.phoneNumber.includes(searchText)
      );
    });
  
    // Update the count and current page state
    updateCount(Math.ceil(filteredData.length / postPerPage));
    updateCurrentPage(1);
  
    // Update the dataSet state with the filtered data
    updateDataSet(filteredData);
  };
  
  const currentPosts = filteredData.slice(indexOfFirst, indexOfLast);

  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };

  useEffect(() => {
    updateCount(Math.ceil(filteredData.length / postPerPage)); // Update count based on filteredData
  }, [filteredData]);

  useEffect(() => {
    (async () => {
      const res = await GetCustomers();
      if (res.successful && res.customersList !== undefined) {
        updateDataSet(res.customersList);
      }
    })();
  }, []);

  const handleStatus = (localData) => {
    // Hit the URL
    let newArray = [...dataSet];
    newArray.forEach((data) => {
      if (data.id === localData.id) {
        if (data.status === 'active') {
          data.status = 'inActive';
        } else {
          data.status = 'active';
        }
      }
    });
    updateDataSet(newArray);
  };

  const elements = [];

  return (
    <Fragment>
      <Row style={{ margin: '2% 1%' }}>
        {elements.map((element) => (
          <Fragment key={element.id}>
            <Col xl={3} md={4} sm={6} xs={12} key={element.id} className={classesX.Col}>
              <div className={classesX.Box}>
                <div>
                  <i className={element.icon} style={{ padding: '5%', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}></i>
                </div>
                <center>
                  <p style={{ fontSize: '1.2rem' }}>{element.type}:</p>
                </center>
              </div>
            </Col>
          </Fragment>
        ))}
      </Row>
      <div className="container">
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
          }}
        >
          <input
            placeholder="Customer Id/Email Id/ Phone Number"
            type="text"
            dir="auto"
            className="SearchField"
            onChange={(e) => updateSearch(e.target.value)} // Update search query state
            value={search}
            style={{
            width: "50vw",
              position: 'relative',
              verticalAlign: 'top',
              borderRadius: '1.571em 0 0 1.571em',
              border: '2px solid steelblue',
            }}
          />
          <Button
            onClick={() => handleFilter()}
            style={{
              backgroundColor: 'steelblue',
              borderRadius: '0 1.571em 1.571em 0',
              border: '1px solid steelblue',
            }}
          >
            <i className="fas fa-search"></i>
          </Button>
        </Container>
        <Table responsive>
          <thead>
            <tr>
              <th>S No.</th>
              <th>Customer Id</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentPosts.map((data, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{data.customerId}</td>
                <td>{data.emailId}</td>
                <td>{data.phoneNumber}</td>
                <td>
                  {data.active === true ? (
                    <Button variant="success" onClick={() => handleStatus(data)}>
                      Active
                    </Button>
                  ) : (
                    <Button variant="danger" onClick={() => handleStatus(data)}>
                      Inactive
                    </Button>
                  )}
                </td>
                <td className={classes.CenterRow}>
                  <Button style={{ marginLeft: '-50px' }} onClick={() => props.click(data)}>
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15px 0' }}>
          <Page count={count} change={handleChangePage} page={currentPage} />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerList;
