import React, {useState, Fragment } from 'react';
import API from './API.js';
import {Col,Row, Container} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import classesX from './Dashboard/dashboard.module.css'
import AdminNavbar from './navbar/adminNavbar.js';
import ApiTransactionList from './apiTransactionList.js';

const APIManagement = (props)=>{
    const [active,updateActive] = useState('2');
    const items = [
        // {id:"1",type:"Message API",val:"message"},
        {id:"2",type:"Flight API",val:"flight"},
        {id:"3",type:"Cab API",val:"cab"},
        {id:"4",type:"Bus API",val:"bus"},
        {id:"5",type:"Hotel API",val:"hotel"},
        {id:"6",type:"Insurance API",val:"insurance"},
        {id:"7",type:"API Transaction Log",val:"log"},
    ]
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>API Management</h1>  
        <Container fluid style={{paddingLeft:'10px', width: '90%' }}>
            <Row style={{margin:'4% 0 0 0'}}> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                  
                                           style={{
                                    backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                    textAlign: 'center',
                                    margin: '0 1px', // Adjust the margin as needed
                                    padding:" 2% 0",
                                    color: 'white',
                                    cursor: 'pointer',
                                    width:"20%",
                                    height:"100%"
                                }} >
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{marginTop:"5vh",height:'100%'}}> 
                        {
                            items.map(item=>
                                active===item.id&&item.id!=="7"?<API type={item.type} val={item.val}/>:null
                            )
                        }
                        {
                            active==="7"?<ApiTransactionList/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default APIManagement;