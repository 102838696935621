export const PincodeGetter = async(mydata) => {
    console.log(mydata);
        const data = {
          data:{
            pincode:mydata,
            access_token:"4725915e3905f4d3eafc7d47ba899e11",
            secret_key:"5c2df44975a966bbf70a325a61c9c70d"
          }
          
        };
        const myresponse = await fetch("https://manage.ithinklogistics.com/api_v3/pincode/check.json", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          
          body: JSON.stringify(data),
        });
        const myjson = await myresponse.json();
        console.log(myjson)
        return myjson.data[`${mydata}`];
};