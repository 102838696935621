import React, { useEffect, useState } from 'react';
import { Table, Container, Button } from 'react-bootstrap';
import Page from './pagination/pagination';

const ApiTransactionList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);

  const fetchData = async () => {
    
    const payload = {
      EndUserIp: '103.154.185.135',
      ClientId: '180070',
      UserName: 'iamtrave',
      Password: 'YjE45x4*Pq',
    };

    try {
      const response = await fetch("https://flight.embarkapi.com/v8/rest/BalanceLog", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("data from api",data)

      if (data) {
     
        updateDataSet(data);
        updateCount(Math.ceil(data.length / postPerPage));
        updateCurrentPage(1);
      } else {
        console.error('No data received from the API');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, []);

  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };

  return (
    <Container fluid style={{ padding: '0' }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Transaction ID</th>
            <th>Transaction Remarks</th>
            <th>Transaction Date</th>
            <th>Transaction Time</th>
            <th>Transaction Debit Amount</th>
            <th>Transaction Credit Amount</th>
            <th>Balance Amount </th>
          </tr>
        </thead>
        <tbody>
          {dataSet.length > 0 ? (
            dataSet.slice((currentPage - 1) * postPerPage, currentPage * postPerPage).map((data, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{data.transactionId}</td>
                <td>{data.transactionRemarks}</td>
                <td>{data.transactionDate}</td>
                <td>{data.transactionTime}</td>
                <td>{data.transactionDebitAmount}</td>
                <td>{data.transactionCreditAmount}</td>
                <td>{data.balanceAmount}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '15px 0' }}>
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};

export default ApiTransactionList;
