import React,{useState} from 'react';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import { Container,Row,Col } from 'react-bootstrap';
import Pages from './pages';

const About = (props)=>{
    const [active,updateActive] = useState("19");
    const handleActive = (id)=>{
        updateActive(id);
        props.subActive(id);
    }
    return(
        <Container fluid>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col xs={3} className={classes.Col} 
                style={{backgroundColor:`${active==='19'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>handleActive("19")}>
                    About Us
                </Col>
                <Col xs={3} className={classes.Col}
                style={{backgroundColor:`${active==='20'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>handleActive("20")}>
                    Whitewular Stories
                </Col>
                <Col xs={3} className={classes.Col}
                style={{backgroundColor:`${active==='21'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>handleActive("21")}>
                    Investors Stories
                </Col>
                <Col xs={3} className={classes.Col}
                style={{backgroundColor:`${active==='22'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>handleActive("22")}>
                    Leadership Team
                </Col>
            </Row>
            {
                active==="19"?<Pages id={props.id} type="About Us"/>:
                active==="20"?<Pages id={props.id} type="Whitewular Stories"/>:
                active==="21"?<Pages id={props.id} type="Investors Stories"/>:
                active==="22"?<Pages id={props.id} type="Leadership Team"/>:null
            }
        </Container>
    )
}

export default About;