import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';

const CreateDeposit = (props)=>{
    const months = [1,2,3,4,5,6,7,8,9,10,11,12];
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                    const newdata = {
                        "depositFrequency": values.depositeType,
                        "emailId": "string",
                        "month10ROI": values.roi10,
                        "month11ROI": values.roi11,
                        "month12ROI": values.roi12,
                        "month1ROI": values.roi1,
                        "month2ROI": values.roi2,
                        "month3ROI": values.roi3,
                        "month4ROI": values.roi4,
                        "month5ROI": values.roi5,
                        "month6ROI": values.roi6,
                        "month7ROI": values.roi7,
                        "month8ROI": values.roi8,
                        "month9ROI": values.roi9,
                        "sessionKey":"string",
                        "theme": values.theme,
                        "travelDepositId": 0
                    }
                    const myresponse = await fetch('https://api.zupptravel.com/addTravelDeposit', {
                        method: "post",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(newdata),
                      });
                      const json = await myresponse.json();
                      if(json.successful){
                          alert('Travel deposit added successfully!')
                          props.click();
                      }else{
                          alert('Error! Please try again later!')
                      }
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicTheme" as={Row}> 
                                            <Form.Label column sm="3">Theme:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Theme" 
                                                name="theme"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.theme}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        {
                                            months.map((month,key)=>
                                            <Form.Group controlId={`formBasicRoi${key}`} as={Row} key={key}>
                                                <Form.Label column sm="3">Month {month} ROI:</Form.Label>
                                                <Col sm="9"> 
                                                <Form.Control 
                                                    type="number" 
                                                    placeholder="Enter ROI" 
                                                    name={`roi${month}`}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values[`roi${month}`]}
                                                    required/>
                                                </Col>
                                            </Form.Group>  
                                            )
                                        }  
                                        <Form.Group controlId="formBasicDepositeType" as={Row}>
                                            <Form.Label column sm="3">Deposit Frequency:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control 
                                                    as="select"
                                                    name="depositeType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.depositeType}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="one-time">one-time</option>
                                                        <option value="monthly">monthly</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <center>
                                            <Button type="submit" variant="success">Save Travel Deposit</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default CreateDeposit;