import React, {useState, Fragment } from 'react';
import {Col,Row, Container } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css';
import Pages from './pages';
import AdminNavbar from '../navbar/adminNavbar';
import About from './about';

const PageManagement = (props)=>{
    const [active,updateActive] = useState('1');
    const [subActive2,updateSubActive2] = useState("19");
    const items = [
        {id:"1",type:"Privacy Policy"},
        {id:"2",type:"Cancellation, Refund and Payment Policy"},
        {id:"3",type:"User Agreement or Terms of Use"},
        {id:"4",type:"Virtual Money Policy"},
        // {id:"5",type:"Right To Forgetten Policy"},
        {id:"6",type:"User Agreement"},
        {id:"7",type:"Agent Terms & Conditions"},
        {id:"8",type:"Consumers Terms & Conditions"},
        {id:"9",type:"Accommodation Terms & Conditions"},
        {id:"10",type:"Tour Guide Terms & Conditions"},
        {id:"11",type:"Cabs Terms & Conditions"},
        {id:"12",type:"Bus Terms & Conditions"},
        {id:"13",type:"Charter Plane Terms & Conditions"},
        {id:"14",type:"Activities Terms & Conditions"},
        {id:"23",type:"About Company"},
    ]
    return(
        <Fragment> 
            <AdminNavbar/>
            <h1 className={classesX.Heading}>Page Management</h1>  
        <Container fluid>
            <Row> 
                <Col lg={3} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active===item.id?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={9} md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            items.map((item,key)=>
                                active===item.id&&item.id!=="23"?<Pages id={item.id} type={item.type} key={key}/>:null
                            )
                        }
                        {
                            active==="23"?<About id={subActive2} type="Company" subActive={(d)=>updateSubActive2(d)}/>:null                           
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default PageManagement;