import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import Page from "../pagination/pagination";

const AirportList = (props) => {
  const [dataSet, updateDataSet] = useState([]);

  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);
  useEffect(() => {
    (async () => {
      const myresponse = await fetch(`https://api.zupptravel.com/api/v1/airport/getAllAirport`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await myresponse.json();

      if (json.success) {
        updateDataSet(json.airports);
      }
    })();
  }, []);
//   const handleDelete = async(id)=>{
//       const res = await DeleteAirport(id);
//       if(res.success){
//           alert("Successfully Deleted");
//           let arr = await GetActiveCoupon();
//           if(arr!=null){
//               updateDataSet(arr);
//           }
//       }
//   }
  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Airport Name</th>
            <th>Airport Code</th>
            <th>City Name</th>
            <th>City Code</th>
            <th>Country Name</th>
            <th>Country Code</th>
            <th>No. of Airports</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{data.airportId}</td>
                  <td>{data.airportName}</td>
                  <td>{data.airportCode}</td>
                  <td>{data.airportCityName}</td>
                  <td>{data.airportCityCode}</td>
                  <td>{data.airportCountryName}</td>
                  <td>{data.airportCountryCode}</td>
                  <td>{data.airportNumAirports}</td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default AirportList;
