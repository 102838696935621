import React, { useState,useEffect } from 'react';
import { Button,Form,Row,Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {AddDepartment,AddDesignation} from '../../entities/action/action';
import DepartmentDesignationList from './list';

const AddDepartmentOrDesignation = (props)=>{
    const dispatch = useDispatch()
    const [dataSet,updateDataSet] = useState([])
    const [input,SetInput] = useState('');
    console.log(props)
    // useEffect(()=>{
    //     if(props.categoryType=="Department"){
    //     (async()=>{
    //         const myresponse = await fetch('https://api.zupptravel.com/departments', {
    //             method: "get",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             }
    //         });
    //         const myjson = await myresponse.json();
    //         console.log("MYJSON",myjson)
    //         updateDataSet(myjson)
    //     })()
    // }else{
    //     (async()=>{
    //         const myresponse = await fetch('https://api.zupptravel.com/designations', {
    //             method: "get",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             }
    //         });
    //         const myjson = await myresponse.json();
    //         console.log("MYJSON",myjson)
    //         updateDataSet(myjson)
    //     })()
    // }
    // },[props.categoryType])
    // useEffect(()=>{
    //     if(props.categoryType=="Department"){
    //         (async()=>{
    //             const myresponse = await fetch('https://api.zupptravel.com/designations', {
    //                 method: "get",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 }
    //             });
    //             const myjson = await myresponse.json();
    //             console.log(myjson)
    //             // updateDataSet(myjson)
               
    //     }
    //     )
    //     console.log(props.categoryType)
    //     }
    // },[props.categoryType])
    const handleSubmit = async()=>{
        let newData;
        let response;
        if(props.categoryType=="Department"){
            newData = {
                "department": input
            }
            response = await AddDepartment(newData);
        }else{
            newData = {
                "designation": input
            }
            response = await AddDesignation(newData);
        }
        console.log(response)
        if(response==200){
            alert("Successfully Added");
            SetInput('');
            window.location.reload();
        }
    }
    return(
        <div> 
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">{`${props.categoryType}`}:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                        type="text" 
                        placeholder={`Enter ${props.categoryType}`} 
                        name="category"
                        onChange={(e)=>SetInput(e.target.value)}
                        value={input}
                        required/>
                </Col>
            </Form.Group>
            <center>
                <Button onClick={handleSubmit} style={{margin:'2%'}}>Save {`${props.categoryType}`}</Button>
            </center>
            <DepartmentDesignationList type={props.categoryType} data={props.data}/>
        </div>
    )
}
export default AddDepartmentOrDesignation;