import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import classesX from "../Dashboard/dashboard.module.css";
import AdminNavbar from "../navbar/adminNavbar";
import AddAirport from "./addAirport";
import AirportList from "./airportList";
import AddAirline from "./addAirline";
import AirlineList from "./airlineList";

const AirportManagement = (props) => {
  const [active, updateActive] = useState("1");
  const items = [
    { id: "1", type: "Add Airport" },
    { id: "2", type: "Airport List" },
    { id: "3", type: "Add Airline" },
    { id: "4", type: "Airline List" },
  ];

  return (
    <Fragment>
      <AdminNavbar />
      <h1 className={classesX.Heading}>Airport Managements</h1>
      <Container fluid style={{ paddingLeft: "10px", width: '90%' }}>
        <Row>
          <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: "100%" }}>
              <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                      textAlign: 'center',
                      margin: '0 1px', // Adjust the margin as needed
                      padding:" 2% 0",
                      color: 'white',
                      cursor: 'pointer',
                      width:"25%",
                      height:"100%"
                  }}
                  >
                    {item.type}
                  </li>
                ))}
                {/* {active === "3" ? (
                  <li
                    onClick={() => updateActive("2")}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === "3" ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    Back
                  </li>
                ) : null} */}
              </ul>
            </div>
          </Col>
          <Col lg={12} md={12} className={classes.Response}>
            <div style={{ height: "100%" ,marginTop:'1vh',marginLeft:"2vw"}}>
              {
                active === "1" ? (
                  <AddAirport />
                ) : active === "2" ? (
                  <AirportList />
                ) : active == "3" ? (
                  <AddAirline />
                ) : active == "4" ? (
                  <AirlineList />
                ) : null
                // active==="3"?<CouponView data={couponData}/>:null
              }
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AirportManagement;
