import React,{useState,useEffect} from 'react';
import { Fragment } from 'react';
import {Modal,Button,Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {RequestRechargeApi} from '../entities/action/action';

const RequestRecharge =(props)=> { 
    const [show, setShow] = useState(false);
    const [amount,updateAmount] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [success,updateSuccess] = useState(false);
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const handleSubmit = async()=>{
        const newData = {
            "loginEmailId": userData.data.key,
            "sessionKey": userData.sessionId,
            "type": `INR ${amount} in ${props.type} API`
        }
        const response = await RequestRechargeApi(newData);
        if(response=="success"){
            updateSuccess(true);
            setTimeout(()=>{
                updateSuccess(false);
            },5000)
        }else{
            alert("Somthing went wrong!!");
        }
    }
    return (
      <>
        <Button variant="secondary" style={{margin:'5% 1%'}} onClick={handleShow}>Recharge Request</Button>
        <Modal show={show} onHide={handleClose} centered>
        {
            success?
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>:
                <Fragment> 
                <Modal.Header>
                    <p style={{fontSize:'1.3rem',fontFamily:"sans-serif",fontWeight:'bold'}}>
                        Recharge Request Amount?
                    </p>
                    <p>
                        <Form.Control 
                            placeholder="Amount" 
                            type="number"
                            value={amount}
                            min={0}
                            onChange={(e)=>updateAmount(e.target.value)}
                        />
                        
                    </p>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                    Continue
                    </Button>
                </Modal.Footer>
            </Fragment>
        }
        </Modal>
      </>
    );
  }

  export default RequestRecharge;